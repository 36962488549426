import React, { Fragment } from "react";
import { questions } from "../../constants";
import OnboadingCard from "../onboadingCard";
import { QuestionTopicProps } from "./types";

import "./styles.scss";

const QuestionTopic = ({ nextStep, setCheckedThemeId, marketingOnboardingAnswersHandler }: QuestionTopicProps) =>
(
  <div className="question-topic">
    <p className="onboarding-title">Какую тему хотите обсудить?</p>
    <div className="question-topic__block-cards">
      {questions.step2.map(({ icon, text }, index) =>
      (
        <Fragment key={index}>
          <OnboadingCard
            text={text}
            iconType={icon}
            nextStep={nextStep}
            setClickElementId={setCheckedThemeId}
            idElement={index + 1}
            question={"Какую тему хотите обсудить?"}
            marketingOnboardingAnswersHandler={marketingOnboardingAnswersHandler}
          />
        </Fragment>
      ))}
    </div>
  </div>
);

export default QuestionTopic;
