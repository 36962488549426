import React from "react";

import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { Button, ButtonSizeEnum } from "@/components/common/button";

import { GameStartComponentProps } from "./types";

import "./styles.scss";

const GameStartComponent = ({ startGame, marketingOnboardingAnswersHandler }: GameStartComponentProps) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "onboardingGame/startCards.png" }) { 
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
    }
  `);

  const startGameHandler = () => {
    startGame();
    marketingOnboardingAnswersHandler("Вытащить карту", "Вытащить карту");
  };

  const image = getImage(data.file);
  return (
    <div className="start-game">
      {image
        && (
          <GatsbyImage
            className="start-game__image"
            image={image}
            alt="Стартовые карты"
          />
        )}
      <p className="start-game__title">
        Получите совет от карт
        <br />
        {" "}
        прямо сейчас
      </p>
      <p className="start-game__description">
        Сконцентрируйтесь на своём вопросе.
        <br />
        Представьте ситуацию, в которой хотите
        {" "}
        <br />
        разобраться, и вытяните карту Таро.
      </p>
      <Button text="Вытащить карту" size={ButtonSizeEnum.Middle} onClick={startGameHandler} />
    </div>
  );
};

export default GameStartComponent;
