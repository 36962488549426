import React from "react";

import logo from "@images/logo_slogan.svg";
import logoWhite from "@images/logo_slogan_white.svg";

import OnboardingBanner from "@/components/layout/header/OnboardingBanner";

import { Icon, IconSizeEnum, IconTypeEnum } from "../../../common/icon";

import StepsComponent from "./StepsComponent";
import { ModalWithStepProps } from "./types";

import "./styles.scss";

const ModalWithStep = ({
  children,
  prevStep,
  closeModalHandler,
  step,
}: ModalWithStepProps) => {
  const isShow = true;
  const isBlackMode = [3, 4, 5].includes(step);
  const StepLineComponentRequired = [1, 2, 3, 4, 5].includes(step);

  return (
    <div
      className={`modal-game-step__container${isShow ? " is-opened" : ""} ${`modal-game-step--${step}-container`}`}
      onClick={closeModalHandler}
    >
      <div className={`modal-game-step ${`modal-game-step--${step}`}`}>
        <div className="modal-game-step__inner">
          <div
            className="modal-game-step__form"
            onClick={(e) =>
              e.stopPropagation()}
          >
            {step <= 6 && <OnboardingBanner />}
            <div className="modal-game-step__top">

              {step === 7 ? (
                <div className="modal-game-step__icon-block">
                  <div className="modal-game-step__time-icon-background" />
                  <Icon className="modal-game-step__time-icon" type={IconTypeEnum.TimeGradient} size={IconSizeEnum.Size40} viewBox="40" />
                </div>
              )
                : <img itemProp="image" src={isBlackMode ? logoWhite : logo} alt="Логотип Lunaro" />}
              {step !== 6 && (
                <Icon
                  className={`modal-game-step__close ${isBlackMode ? "white" : ""}`}
                  onClick={closeModalHandler}
                  type={IconTypeEnum.Close}
                  size={IconSizeEnum.Size32}
                />
              )}

              {step <= 5 && step !== 1 && (
                <Icon
                  className={`modal-game-step__back ${isBlackMode ? "white" : ""}`}
                  onClick={prevStep}
                  type={IconTypeEnum.Left}
                  size={IconSizeEnum.Size32}
                />
              )}
            </div>
            {StepLineComponentRequired && <StepsComponent step={step} isBlackMode={isBlackMode} />}
            <div className="modal-game-step__content">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalWithStep;
