import React from "react";

import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, IGatsbyImageData, getImage } from "gatsby-plugin-image";

import { Button, ButtonIconPositionEnum, ButtonSizeEnum } from "@/components/common/button";
import { IconTypeEnum } from "@/components/common/icon";

import { ImageQueryResult, RandomCardComponentProps } from "./types";

import "./styles.scss";

const RandomCardComponent = ({
  selectedCard,
  nextStep,
  marketingOnboardingAnswersHandler,
}: RandomCardComponentProps) => {
  const data = useStaticQuery<ImageQueryResult>(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "onboardingGame" } }) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED, 
              placeholder: BLURRED,
              width: 155,
              height: 247,
            )
          }
        }
      }
    }
  `);

  const images = data.allFile.nodes.reduce<Record<string, IGatsbyImageData | undefined>>((acc, node) => {
    if (node.childImageSharp) {
      acc[node.name] = getImage(node.childImageSharp) || undefined;
    }
    return acc;
  }, {} as Record<string, IGatsbyImageData | undefined>);

  const nextStepHandler = () => {
    nextStep();
    marketingOnboardingAnswersHandler("Получить расклад от таролога", "Получить расклад от таролога");
  };

  return (
    <div className="start-game">
      {selectedCard && (
        <div className="selected-card">
          {images[selectedCard.id] ? (
            <GatsbyImage
              className="start-game__card-image"
              image={images[selectedCard.id] as IGatsbyImageData}
              alt={selectedCard.title}
            />
          ) : null}
          <div className="start-game__information">
            <p className="start-game__information__title">{selectedCard.title}</p>
            <p className="start-game__information__description">{selectedCard.description}</p>
          </div>
          <p className="start-game__info">Это лишь первый знак. Готовы получить полный расклад и точные ответы на свои вопросы?</p>
          <Button
            text="Получить расклад от таролога"
            size={ButtonSizeEnum.Middle}
            onClick={nextStepHandler}
            icon={IconTypeEnum.Right}
            iconPosition={ButtonIconPositionEnum.Right}
          />
        </div>
      )}
    </div>
  );
};

export default RandomCardComponent;
