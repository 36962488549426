import React, { Fragment } from "react";
import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";
import { CommentCardProps } from "./types";

import "./styles.scss";

const CommentCard = ({ clientName, date, rate, img, comment }: CommentCardProps) =>
(
  <div className="comment-card-container">
    <div className="comment-card-container__top">
      <div className="comment-card-container__block-info">
        <img itemProp="image" className="comment-card-container__client-img" src={img} alt="client img" />
        <div className="comment-card-container__block-name">
          <span className="comment-card-container__name">{clientName}</span>
          <span className="comment-card-container__date">{date}</span>
        </div>
      </div>
      <div className="comment-card-container__block-rates">
        {Array.from({ length: rate }).map((_, index) =>
        (
          <Fragment key={index}>
            <Icon type={IconTypeEnum.Fav} size={IconSizeEnum.Size20} />
          </Fragment>
        ))}
      </div>
    </div>
    <p className="comment-card-container__comment-text">{comment}</p>
  </div>
);

export default CommentCard;
