import React, { useEffect, useState } from "react";

import CircularProgressbarComponent from "../circularProgressbar";
import CommentsFromClients from "../commentsFromClients";

import { TITLE_TEXTS_OPTIONS } from "./constants";
import { ProcessFindResultProps } from "./types";

import "./styles.scss";

const ProcessFindResult = ({ nextStep }: ProcessFindResultProps) => {
  const [textIndex, setTextIndex] = useState(0);

  useEffect(() => {
    const textInterval = setInterval(() => {
      setTextIndex((prevIndex) => {
        if (prevIndex >= TITLE_TEXTS_OPTIONS.length - 1) {
          clearInterval(textInterval);
          return prevIndex;
        }
        return prevIndex + 1;
      });
    }, 2000);

    return () =>
      clearInterval(textInterval);
  }, []);

  return (
    <div className="process-find-result">
      <p className="process-find-result__title">
        {TITLE_TEXTS_OPTIONS[textIndex]}
      </p>
      <CircularProgressbarComponent nextStep={nextStep} infoText="Анализируем ваши ответы..." />
      <CommentsFromClients />
    </div>
  );
};
export default ProcessFindResult;
