import React from "react";
import { Icon } from "@/components/common/icon";
import { OnboadingCardProps } from "./types";

import "./styles.scss";

const OnboadingCard = ({
  text,
  iconType,
  nextStep = () => {},
  setClickElementId = () => {},
  idElement,
  feelingAnswer,
  question,
  marketingOnboardingAnswersHandler,
}: OnboadingCardProps) => {
  const clickCardHandler = () => {
    if (question && text) {
      marketingOnboardingAnswersHandler(question, text);
    }

    nextStep();
    if (idElement) {
      setClickElementId(idElement);
    }
  };

  return (
    <div
      className={`onboading-card ${feelingAnswer === idElement ? "onboading-card--active" : ""}`}
      onClick={clickCardHandler}
    >
      <Icon type={iconType} />
      <p>{text}</p>
    </div>
  );
};

export default OnboadingCard;
