import React, { useState } from "react";

import { useLocation } from "@reach/router";

import { UrlParamKeyEnum } from "@/components/constants";
import { setAuthParamToURL } from "@/components/layout/modals/utils";
import { useMarketingHook } from "@/marketing/marketingHook";

import StepContent from "./components/StepContent";
import { Card, cards } from "./constants";
import ModalWithStep from "./modalWithStep";

import "./styles.scss";

interface OnboardingModalProps {
  urlParamKey: UrlParamKeyEnum
}

const OnboardingModalGame = ({ urlParamKey }: OnboardingModalProps) => {
  const { marketingOnboardingAnswers } = useMarketingHook();
  const [step, setStep] = useState(1);
  const [checkedThemeId, setCheckedThemeId] = useState<number | undefined>();
  const location = useLocation();
  const [selectedCard, setSelectedCard] = useState<Card | null>(null);

  const nextStep = (stepNumber?: number) => {
    if (stepNumber) {
      setStep(stepNumber);
    } else {
      setStep(step + 1);
    }
  };

  const startGame = () => {
    const randomIndex = Math.floor(Math.random() * cards.length);
    setSelectedCard(cards[randomIndex]);
    nextStep();
  };

  const prevStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const closeModalHandler = () => {
    setStep(1);
    setCheckedThemeId(undefined);
    setAuthParamToURL(location, null, urlParamKey);
  };

  const marketingOnboardingAnswersHandler = (question: string = "", answer: string = "") => {
    marketingOnboardingAnswers(`${step} - ${question}`, answer);
  };

  return (
    <ModalWithStep
      prevStep={prevStep}
      closeModalHandler={closeModalHandler}
      step={step}
    >
      <div className="onboarding-modal">
        <StepContent
          step={step}
          checkedThemeId={checkedThemeId}
          nextStep={nextStep}
          setCheckedThemeId={setCheckedThemeId}
          marketingOnboardingAnswersHandler={marketingOnboardingAnswersHandler}
          startGame={startGame}
          selectedCard={selectedCard}
        />
      </div>
    </ModalWithStep>
  );
};

export default OnboardingModalGame;
