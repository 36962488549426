import React from "react";

import { StepsComponentProps } from "./types";
import "./styles.scss";

interface Props extends StepsComponentProps {
  isBlackMode?: boolean;
}

const StepsComponent = ({ stepText, step, isBlackMode = false }: Props) =>
(
  <div className={`step-container ${isBlackMode ? "black-mode" : ""}`}>
    {stepText && <p className="step-container__title">{stepText}</p>}
    <div className="step-container__lines">
      {Array.from({ length: 5 }).map((_, index) =>
      (
        <div
          key={index}
          className={`step-container__lines--step-item ${step >= index + 1 ? "step-container__lines--active" : ""
            }`}
        />
      ))}
    </div>
  </div>
);

export default StepsComponent;
