import React, { useEffect, useState } from "react";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

import { CircularProgressbarProps } from "./types";
import "./styles.scss";

const CircularProgressbarComponent = ({
  nextStep,
  infoText,
  percentColor = "#000000",
}: CircularProgressbarProps) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setValue((prevValue) => {
        if (prevValue >= 1) {
          clearInterval(interval);
          return 1;
        }
        return prevValue + 0.04;
      });
    }, 100);

    return () =>
      clearInterval(interval);
  }, []);

  useEffect(() => {
    if (value === 1) {
      const timeout = setTimeout(() => {
        nextStep();
      }, 2000);

      return () =>
        clearTimeout(timeout);
    }
  }, [nextStep, value]);

  return (
    <div className="progressbar">
      <div className="progressbar-block">
        <div className="progressbar-background" />
        <CircularProgressbar
          value={value}
          maxValue={1}
          text={`${Math.round(value * 100)}%`}
          strokeWidth={6}
          styles={buildStyles({
            textSize: "22px",
            pathColor: "#A656D0",
            textColor: percentColor,
            trailColor: "#E2C2F4",
            backgroundColor: "transparent",
          })}
        />
      </div>
      {infoText && <p className="progressbar__info-text">{infoText}</p>}
    </div>
  );
};

export default CircularProgressbarComponent;
