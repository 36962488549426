import React from "react";

import CircularProgressbarComponent from "../circularProgressbar";
import FinalModalContent from "../finalModalContent";
import GameStartComponent from "../gameStartComponent";
import PopularQuestions from "../popularQuestions";
import ProcessFindResult from "../processFindResult";
import QuestionTopic from "../questionTopic";
import RandomCardComponent from "../randomCardComponent";

import { StepContentProps } from "./types";

const StepContent: React.FC<StepContentProps> = ({
  step,
  nextStep,
  setCheckedThemeId,
  checkedThemeId,
  marketingOnboardingAnswersHandler,
  startGame,
  selectedCard,
}) => {
  switch (step) {
    case 1:
      return (
        <QuestionTopic
          nextStep={nextStep}
          setCheckedThemeId={setCheckedThemeId}
          marketingOnboardingAnswersHandler={marketingOnboardingAnswersHandler}
        />
      );
    case 2:
      return (
        <PopularQuestions
          nextStep={nextStep}
          checkedThemeId={checkedThemeId}
          marketingOnboardingAnswersHandler={marketingOnboardingAnswersHandler}
        />
      );
    case 3:
      return (
        <GameStartComponent
          startGame={startGame}
          marketingOnboardingAnswersHandler={marketingOnboardingAnswersHandler}
        />
      );
    case 4:
      return (
        <CircularProgressbarComponent
          nextStep={nextStep}
          infoText="Подбираем карту..."
          percentColor="#FFF"
        />
      );
    case 5:
      return (
        <RandomCardComponent
          selectedCard={selectedCard}
          nextStep={nextStep}
          marketingOnboardingAnswersHandler={marketingOnboardingAnswersHandler}
        />
      );
    case 6:
      return <ProcessFindResult nextStep={nextStep} />;
    case 7:
      return <FinalModalContent marketingOnboardingAnswersHandler={marketingOnboardingAnswersHandler} />;
    default:
      return <div>Неизвестный шаг</div>;
  }
};

export default StepContent;
